<template>
  <v-row>
    <!-- Studios auswählen -->
    <v-col align="center" cols="12">
      <v-card max-width="500px" dark class="px-5 py-2" flat>
        <v-select :loading="loading" :disabled="loading" label="Studios" :items="availableStudios" v-model="selectedStudios" multiple></v-select>
        <v-select
          @change="fetchHealthcareRequests"
          :loading="loading"
          :disabled="loading"
          label="Status"
          :items="[
            { text: 'Offen', value: 'pending' },
            { text: 'Abgeschlossen', value: 'accepted' },
          ]"
          v-model="selectedStatus"></v-select>
      </v-card>
      <v-divider color="white"></v-divider>
    </v-col>

    <!-- Lade-Spinner -->
    <v-col align="center" cols="12" v-if="loading">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </v-col>

    <!-- Keine Daten gefunden -->
    <v-col align="center" cols="12" v-if="!loading && requestsFiltered.length === 0">
      <v-alert max-width="500px" type="info">Keine Anfragen gefunden</v-alert>
    </v-col>

    <v-col align="center" v-for="(requestItem, index) in requestsFiltered" :key="requestItem._id" class="mb-2" xl="4" lg="6" md="6" sm="12" xs="12">
      <v-card class="rounded-lg fill-height" align="left" max-width="500px" dark flat>
        <!-- Beispiel: Studio-ID und Status anzeigen -->
        <v-card-subtitle class="mb-0 pb-0">
          Studio: {{ requestItem.studio.name }} <v-chip small class="ml-2"> {{ $moment(requestItem.createdAt).format("DD.MM.YYYY") }}</v-chip>
        </v-card-subtitle>

        <!-- Titel des Vertrags -->
        <v-card-title class="mt-0 pt-0"> {{ requestItem.user.vorname }} {{ requestItem.user.nachname }} ({{ requestItem.user.email }}) </v-card-title>

        <!-- User/Customer-Info (aktuell haben wir nur die ID) -->
        <v-card-subtitle class="pb-0">
          Vertragstyp:
          {{ requestItem.contract.title }}
        </v-card-subtitle>

        <v-row no-gutters>
          <v-col cols="12">
            <v-row no-gutters>
              <v-col cols="8">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-card-subtitle>
                      <v-chip small color="primary"> Start: {{ requestItem.contract.start }} </v-chip>
                      <v-chip small color="primary" class="ml-2"> Ende: {{ requestItem.contract.end }} </v-chip>
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-col>

              <!-- "Freischalten"- oder Aktions-Button rechts -->
              <v-col class="d-flex justify-end align-center" cols="4" v-if="requestItem.status === 'pending'">
                <v-btn
                  @click="
                    selectedRequest = {
                      ...requestItem,
                      amount: 0,
                    }
                  "
                  color="orange"
                  class="mr-5">
                  <v-icon>mdi-lock-open</v-icon>
                </v-btn>
              </v-col>
              <v-col class="d-flex justify-end align-center" cols="4" v-if="requestItem.status === 'accepted'">
                <v-btn @click="createPDF(requestItem)" color="green" class="mr-5">
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <!-- Dialog zur Bearbeitung/Freischaltung -->
    <v-dialog max-width="500px" persistent :value="selectedRequest !== null">
      <v-card v-if="selectedRequest && selectedRequest.contract && selectedRequest.contract.title">
        <v-card-title style="background-color: var(--v-primary)" class="white--text">
          {{ selectedRequest.user.vorname }} {{ selectedRequest.user.nachname }} ({{ selectedRequest.user.email }})
        </v-card-title>

        <!-- Beispiel: Alert, falls du bestimmte Zusatz-Logik hast (wurde hier entfernt) -->
        <!-- <v-alert class="ma-5" color="orange" type="info" v-if="???">
                    ...
                </v-alert> -->

        <v-card-title class="h5 mt-2">Kundendaten</v-card-title>
        <v-card-text class="pb-0">
          Studio:
          {{ selectedRequest.studio.name }} <br />
          Vorname: {{ selectedRequest.user.vorname }} <br />
          Nachname: {{ selectedRequest.user.nachname }} <br />
          E-Mail: {{ selectedRequest.user.email }}
        </v-card-text>

        <v-card-title>gefundene Zahlungen</v-card-title>
        <v-row no-gutters style="max-width: 100%">
          <div class="mb-1 d-flex" style="max-width: 100%" v-for="trans of contractTransactions">
            <v-col cols="10">
              <v-card class="pa-1 rounded-xl d-flex">
                <v-col cols="2" class="pa-0 align-content-center">
                  <v-img src="/img/lottiefiles/transactions/typeMembership.png"></v-img>
                </v-col>
                <v-col cols="10">
                  <v-card-subtitle class="pa-0">{{ trans.Description }}</v-card-subtitle>
                  <v-card-subtitle class="pa-0"
                    ><span :class="{ 'red--text': trans.Value.value < 0 }">{{ trans.Value.value + " " + trans.Value.currencyCode }}</span>
                    <span class="red--text">{{ additionalPaymentInfos(trans) }}</span></v-card-subtitle
                  >
                </v-col>
              </v-card>
            </v-col>
            <v-col cols="2"> <v-checkbox hide-details v-model="trans.checked" color="primary"></v-checkbox></v-col>
          </div>
        </v-row>
        <v-card-actions class="justify-content-center"
          ><v-btn color="primary" @click="insertCost()">übernehmen <v-icon>mdi-arrow-down-thick</v-icon></v-btn></v-card-actions
        >
        <!-- Betrag/Kosten -->
        <v-text-field class="mx-5 mt-5" type="number" v-model="selectedRequest.amount" label="Kosten des Abos (KK-Bestätigung)"></v-text-field>
        <v-text-field class="mx-5 mt-5" v-model="selectedRequest.contract.title" label="Abo Titel"></v-text-field>
        <v-text-field class="mx-5 mt-5" type="text" v-model="selectedRequest.packages" label="Pakete:"></v-text-field>
        <v-checkbox class="mx-5 mt-5" type="number" v-model="selectedRequest.wellness" label="Wellness und oder Bad inkludiert"></v-checkbox>

        <v-card-actions class="justify-space-between">
          <v-btn @click="selectedRequest = null" text>Abbrechen</v-btn>
          <v-btn color="orange" class="white--text" @click="createPDF(selectedRequest)"> Test PDF erstellen </v-btn>
          <v-btn @click="updateContractPayment" color="primary">Freischalten</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import api from "@/api"

export default {
  data() {
    return {
      selectedStatus: "pending",
      loading: true,
      requests: [], // Hält die Array-Response vom Server
      selectedStudios: [], // Vom Nutzer gewählte Studios (IDs oder ggf. Namen)
      selectedRequest: null, // Aktuell ausgewähltes Objekt für den Dialog
    }
  },
  created() {
    this.fetchHealthcareRequests()
  },
  methods: {
    /**
     * PDF erstellen
     */
    async createPDF(data) {
      // Da sich das Datenmodell geändert hat,
      // passe deine Payload hier an deine neuen Felder an:
      const request = data
      const formData = {
        test: true,
        contract: {
          wellness: data.wellness,
          ContractID: request.contract.ContractID,
          startDate: request.contract.start,
          endDate: request.contract.end,
          amount: request.amount,
          packages: data.packages,
          membershipName: request.contract.title,
          studioId: request.studio,
          // Das hier sind nur Beispielwerte
          studioPopulated: {
            art: null,
            adresse: null,
            healtcareConfirmationNumber: null,
            logo: null,
            website: null,
          },
        },
        customerId: request.user,
        // Falls du Kundendetails hast, bitte hier anpassen.
        customerInfos: {
          healthcareName: "Testversicherung",
          additionalInsurance: "Testzusatzversicherung",
          insuranceNumber: "Testversicherungsnummer",
          firstName: "Vorname",
          lastName: "Nachname",
          street: "Strasse",
          zip: "PLZ",
          city: "Stadt",
          signature: null,
        },
      }

      try {
        this.$toast.success("PDF wird erstellt")
        await api.createHealthcarePDF(formData)
      } catch (e) {
        console.error(e)
        this.$toast.error("Fehler beim Herunterladen, bitte versuche es erneut")
      }
    },
    additionalPaymentInfos(trans) {
      if (!this.selectedRequest.contract.yearCount) return ""

      return " / " + this.selectedRequest.contract.yearCount + " = " + Number(trans.Value.value) / this.selectedRequest.contract.yearCount + " CHF"
    },
    /**
     * Beispiel-Funktion zum Freischalten / Payment updaten
     */
    async updateContractPayment() {
      if (this.selectedRequest?.amount > 0 && this.selectedRequest?._id && this.selectedRequest?.user) {
        try {
          await api.updateHealthcareRequest({
            amount: this.selectedRequest.amount,
            packages: this.selectedRequest.packages,
            wellness: this.selectedRequest.wellness,
            status: "accepted",
            title: this.selectedRequest.contract.title,
            requestId: this.selectedRequest._id,
          })
          this.$toast.success("Status aktualisiert")
          this.selectedRequest = null
          this.requests = []
          this.fetchHealthcareRequests()
        } catch (e) {
          console.error(e)
          this.$toast.error("Fehler beim Aktualisieren")
        }
      } else {
        this.$toast.error("Fülle alle Felder aus")
      }
    },

    /**
     * Server-Abfrage
     */
    async fetchHealthcareRequests() {
      this.loading = true
      try {
        // Neue Struktur:
        // [
        //   {
        //     "_id": "...",
        //     "contract": { ... },
        //     "user": "...",
        //     "studio": "...",
        //     ...
        //   }
        // ]
        const response = await api.fetchHealthcareRequestsAdmin(this.selectedStatus)
        this.requests = response
      } catch (error) {
        console.error(error)
        this.$error("Fehler beim Laden der Anfragen")
      } finally {
        this.loading = false
      }
    },
    insertCost() {
      let price = 0
      for (let transaction of this.contractTransactions) {
        if (transaction.checked) {
          let divider = 1

          if (this.selectedRequest.contract.yearCount) {
            divider = this.selectedRequest.contract.yearCount
          }

          price += Number(transaction.Value.value / divider)
        }
      }
      this.selectedRequest.amount = price
    },
  },
  computed: {
    /**
     * Gibt nur die gefilterten Anfragen zurück,
     * je nachdem welche Studios der User ausgewählt hat.
     * Da du in deiner neuen Struktur nur die Studio-ID hast,
     * müsstest du ggf. Mapping (ID -> Name) hinterlegen,
     * oder du vergleichst direkt die IDs.
     */
    requestsFiltered() {
      if (!this.requests.length) {
        return []
      }
      // Falls der Nutzer keine Studios ausgewählt hat, zeig alle
      if (!this.selectedStudios.length) {
        return this.requests
      }
      // Ansonsten filter nach Studio-ID:
      return this.requests.filter((req) => this.selectedStudios.includes(req.studio.name))
    },

    /**
     * Liste aller verfügbaren Studios (IDs),
     * abgeleitet aus den Daten.
     */
    availableStudios() {
      const studioIds = this.requests.map((req) => req.studio.name).filter((id) => !!id)
      return [...new Set(studioIds)]
    },
    contractTransactions() {
      if (!this.selectedRequest.contract.transactions) return []

      this.selectedRequest.packages = ""
      let allTrans = []

      for (let transaction of this.selectedRequest.contract.transactions) {
        for (let detTransaction of transaction.det.AccountTransactionDetail) {
          if (detTransaction.Description.includes("Einschreib") || detTransaction.Description.includes("Aktivierungs")) {
            continue
          } else {
            if (detTransaction.Description.includes("Wellness") || detTransaction.Description.includes("Spa")) {
              this.selectedRequest.wellness = true
            } else if (!detTransaction.Description.includes(this.selectedRequest.contract.title) && !detTransaction.Description.includes("abatt")) {
              this.selectedRequest.packages += detTransaction.Description + ", "
            }

            detTransaction.checked = true
            allTrans.push(detTransaction)
          }
        }
      }
      console.log("hoi", allTrans)
      return allTrans
    },
  },
}
</script>

<style lang="scss" scoped>
.studio-name {
  font-weight: bold;
}

.time {
  font-size: 12px;
  color: #999;
}

.contract-name {
  font-style: italic;
}
</style>
